<template>
  <div class="p-6 lg:p-10 flex flex-row justify-between">
    <div class="flex items-end">
      <router-link class="-m-1.5 p-1.5" to="/">
        <img class="h-8 w-auto" src="../assets/logo.svg" alt="Logo" />
      </router-link>
      <!-- <div class="-ml-3 flex items-end">
        <span class="text-sm text-ternBlue -mb-1"> Beta </span>
      </div> -->
    </div>

    <div class="hidden lg:flex flex-row gap-14 items-center justify-center">
      <router-link to="/#Features"
        ><p class="text-ternBlue hover:font-bold">Features</p></router-link
      >
      <!-- <router-link class="text-ternBlue hover:font-bold" to="/#TravelAgent"
        >Travel Agent</router-link
      > 

      <router-link class="text-ternBlue hover:font-bold" to="/#GiftTravel"
        >Gift travel</router-link
      > -->
      <router-link class="text-ternBlue hover:font-bold" to="/#Testimonials"
        >Testimonials</router-link
      >
      <router-link class="text-ternBlue hover:font-bold" to="/#FAQ"
        >FAQ</router-link
      >
      <a
        class="text-ternBlue hover:font-bold blog-link"
        href="https://travelwithtern.substack.com/"
        target="_blank"
        ><p class="text-ternBlue hover:font-bold">Blog</p></a
      >
      <a
        class="
          text-white
          hover:text-ternYellow
          font-bold
          leading-6
          border border-ternYellow
          bg-ternYellow
          hover:bg-white
          text-center
          rounded rounded-full
          px-6
          py-1
          content-center
        "
        href="https://tern.app/auth"
        >Log in
      </a>
    </div>

    <div class="lg:hidden flex flex-col items-end relative">
      <input type="checkbox" id="checkbox" />
      <label @click.stop="toggleMenu" for="checkbox" class="toggle">
        <div class="bars" id="bar1"></div>
        <div class="bars" id="bar2"></div>
        <div class="bars" id="bar3"></div>
      </label>

      <div v-if="isMenuOpen" class="absolute top-4 z-10">
        <ul
          class="
            flex flex-col
            p-6
            md:p-0
            mt-4
            border border-gray-100
            rounded-lg
            bg-gray-50
            md:space-x-8
            rtl:space-x-reverse
            md:flex-row md:mt-0 md:border-0
            dark:bg-gray-800
            md:dark:bg-gray-900
            dark:border-gray-700
            gap-3
            md:bg-transparent
          "
        >
          <li>
            <router-link class="hover:text-ternBlue" to="/#Features"
              >Features</router-link
            >
          </li>
          <!-- <li>
            <router-link class="hover:text-ternBlue" to="/#TravelAgent"
              >Travel Agent</router-link
            >
          </li> 
          <li>
            <router-link class="hover:text-ternBlue" to="/#GiftTravel"
              >Gift travel</router-link
            >
          </li> -->
          <li>
            <router-link class="hover:text-ternBlue" to="/#Testimonials"
              >Testimonials</router-link
            >
          </li>
          <li>
            <router-link class="hover:text-ternBlue" to="/#FAQ"
              >FAQ</router-link
            >
          </li>
          <li>
            <a
              class="hover:text-ternBlue"
              href="https://travelwithtern.substack.com/"
              target="_blank"
              >Blog</a
            >
          </li>
          <li>
            <a
              class="hover:text-ternBlue font-semibold leading-6 text-gray-900"
              href="https://tern.app/auth"
              >Log in <span aria-hidden="true">&rarr;</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>

<style scoped>
#checkbox {
  display: none;
}

.toggle {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition-duration: 0.5s;
}

.bars {
  width: 100%;
  height: 2px;
  background-color: #58a8e1;
  border-radius: 4px;
}

#bar2 {
  transition-duration: 0.8s;
}

#checkbox:checked + .toggle .bars {
  position: absolute;
  transition-duration: 0.5s;
}

#checkbox:checked + .toggle #bar2 {
  transform: scaleX(0);
  transition-duration: 0.5s;
}

#checkbox:checked + .toggle #bar1 {
  width: 100%;
  transform: rotate(45deg);
  transition-duration: 0.5s;
}

#checkbox:checked + .toggle #bar3 {
  width: 100%;
  transform: rotate(-45deg);
  transition-duration: 0.5s;
}

#checkbox:checked + .toggle {
  transition-duration: 0.5s;
  transform: rotate(180deg);
}

a.blog-link:hover,
a.router-link-active.router-link-exact-active:hover {
  font-weight: 700;
}
</style>
