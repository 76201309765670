<template>
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <footer
      aria-labelledby="footer-heading"
      class="relative border-t border-gray-900/10 py-24 sm:mt-56 sm:py-32"
    >
      <h2 id="footer-heading" class="sr-only">Footer</h2>
      <div class="md:grid md:grid-cols-3 md:gap-8">
        <img class="h-7" src="./../assets/logo.svg" alt="Tern" />
        <div class="mt-16 grid grid-cols-2 gap-8 md:col-span-2 md:mt-0">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold leading-6 text-gray-900">
                Support
              </h3>
              <ul role="list" class="mt-6 space-y-4">
                <li>
                  <a
                    href="mailto:hello@travelwithtern.com"
                    class="text-sm leading-6 text-gray-600 hover:text-gray-900"
                    >Contact Us</a
                  >
                </li>
              </ul>
              <div class="mt-10">
                <h3 class="text-sm font-semibold leading-6 text-gray-900">
                  Legal
                </h3>
                <ul role="list" class="mt-6 space-y-4">
                  <li>
                    <a
                      href="https://travelwithtern.notion.site/Tern-s-Terms-and-Conditions-7e089a5402cb4e73bcb9e8324be6e474"
                      target="_blank"
                      class="
                        text-sm
                        leading-6
                        text-gray-600
                        hover:text-gray-900
                      "
                      >Terms and Conditions</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold leading-6 text-gray-900">
                Company
              </h3>
              <ul role="list" class="mt-6 space-y-4">
                <li>
                  <a
                    href="https://travelwithtern.notion.site/Get-Tern-on-your-Phone-Laptop-in-2-Quick-Steps-c3512f4b1a164573bb37552757740869"
                    target="_blank"
                    class="text-sm leading-6 text-gray-600 hover:text-gray-900"
                    >Get Tern on your phone</a
                  >
                </li>
                <li>
                  <a
                    href="https://travelwithtern.substack.com/"
                    target="_blank"
                    class="text-sm leading-6 text-gray-600 hover:text-gray-900"
                    >Blog</a
                  >
                </li>
                <li>
                  <a
                    href="https://www.phocuswire.com/startup-stage-tern-gpt-technology-for-travel-planning"
                    target="_blank"
                    class="text-sm leading-6 text-gray-600 hover:text-gray-900"
                    >Press</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="border-t border-gray-900/10 mt-24">
        <div
          class="items-center content-center justify-between gap-3 pt-8 flex"
        >
          <span
            class="
              flex
              items-center
              content-center
              justify-start
              text-xs
              leading-5
              text-[#6b7280]
            "
            >© 2025 Tern Travel, Inc. All rights reserved.</span
          >
          <div class="flex items-center content-center justify-end">
            <a
              target="_blank"
              href="https://www.instagram.com/travelwithtern"
              class="ml-5 cursor-pointer"
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.946 5.33081C7.84058 5.33081 5.33569 7.83569 5.33569 10.9412C5.33569 14.0466 7.84058 16.5515 10.946 16.5515C14.0515 16.5515 16.5564 14.0466 16.5564 10.9412C16.5564 7.83569 14.0515 5.33081 10.946 5.33081ZM10.946 14.5886C8.93921 14.5886 7.29858 12.9529 7.29858 10.9412C7.29858 8.92944 8.93433 7.2937 10.946 7.2937C12.9578 7.2937 14.5935 8.92944 14.5935 10.9412C14.5935 12.9529 12.9529 14.5886 10.946 14.5886ZM18.0945 5.10132C18.0945 5.82886 17.5085 6.40991 16.7859 6.40991C16.0584 6.40991 15.4773 5.82397 15.4773 5.10132C15.4773 4.37866 16.0632 3.79272 16.7859 3.79272C17.5085 3.79272 18.0945 4.37866 18.0945 5.10132ZM21.8103 6.42944C21.7273 4.67651 21.3269 3.12378 20.0427 1.84448C18.7634 0.565185 17.2107 0.164795 15.4578 0.0769043C13.6511 -0.0256348 8.23608 -0.0256348 6.42944 0.0769043C4.6814 0.159912 3.12866 0.560303 1.84448 1.8396C0.560303 3.1189 0.164795 4.67163 0.0769043 6.42456C-0.0256348 8.2312 -0.0256348 13.6462 0.0769043 15.4529C0.159912 17.2058 0.560303 18.7585 1.84448 20.0378C3.12866 21.3171 4.67651 21.7175 6.42944 21.8054C8.23608 21.908 13.6511 21.908 15.4578 21.8054C17.2107 21.7224 18.7634 21.322 20.0427 20.0378C21.322 18.7585 21.7224 17.2058 21.8103 15.4529C21.9128 13.6462 21.9128 8.23608 21.8103 6.42944ZM19.4763 17.3914C19.0955 18.3484 18.3582 19.0857 17.3962 19.4714C15.9558 20.0427 12.5378 19.9109 10.946 19.9109C9.35425 19.9109 5.9314 20.0378 4.49585 19.4714C3.53882 19.0906 2.80151 18.3533 2.41577 17.3914C1.84448 15.9509 1.97632 12.533 1.97632 10.9412C1.97632 9.34937 1.84937 5.92651 2.41577 4.49097C2.79663 3.53394 3.53394 2.79663 4.49585 2.41089C5.93628 1.8396 9.35425 1.97144 10.946 1.97144C12.5378 1.97144 15.9607 1.84448 17.3962 2.41089C18.3533 2.79175 19.0906 3.52905 19.4763 4.49097C20.0476 5.9314 19.9158 9.34937 19.9158 10.9412C19.9158 12.533 20.0476 15.9558 19.4763 17.3914Z"
                  fill="#9ca3af"
                />
              </svg>
            </a>
            <a
              target="_blank"
              href="http://tiktok.com/@travelwithtern"
              class="ml-4 cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="21"
                viewBox="0 0 448 512"
                fill="#9ca3af"
              >
                <path
                  d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
                />
              </svg>
            </a>
            <a
              target="_blank"
              href="https://www.twitter.com/travelwithtern"
              class="ml-3 cursor-pointer"
            >
              <svg
                width="24"
                height="20"
                viewBox="0 0 26 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.567383 0L9.66262 14.0842L1.04555 25H4.43029L11.1772 16.4307L16.71 25H25.5674L16.0541 10.2431L24.1179 0H20.7857L14.5445 7.89931L9.45232 0H0.567383ZM5.48927 2.77778H8.11545L20.648 22.2222H18.0444L5.48927 2.77778Z"
                  fill="#9ca3af"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>
